import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import QuestionAndAnswer from './screen/QuestionAndAnswer';

const App = () => {
  return (
    <BrowserRouter>
      <Switch>
        {/* <Route exact path="/" component={Home} /> */}
        <Route exact path="/" component={QuestionAndAnswer} />
        <Route path="/about/abc" component={About} />
        <Route path="/page1" component={TestPage1} />
        <Route path="/page2" component={TestPage2} />

        {/* <Route path="/" component={QuestionAndAnswer} /> */}
      </Switch>
    </BrowserRouter>
  );
};

const Home = () => {
  return <h1>Home Page</h1>;
};

const About = () => {
  return <h1>About Page</h1>;
};

const TestPage1 = () => {
  return <h1>cafe Page1</h1>;
};

const TestPage2 = () => {
  return <h1>cafe Page2</h1>;
};

export default App;