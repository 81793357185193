import React, { useState } from 'react';
import './QuestionAndAnswer.css';
import { get, post } from '../utils/AxiosService'
import axios from 'axios';

const QuestionAndAnswer = () => {
  const [question, setQuestion] = useState('');
  const [answers, setAnswers] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);


  const handleQuestionChange = (e) => {
    setQuestion(e.target.value);
  };

  const handleQuestionSubmit = async (e) => {
    e.preventDefault();
    if (question.trim() !== '') {
      // setAnswers((prevAnswers) => [...prevAnswers, question]);
      const result = await getAnswer(question);


      setAnswers(result)
      setQuestion('');
    }
  };


  // api 提问
  async function getAnswer(question) {
    setIsButtonDisabled(true);

    // const response = await axios.post(
    //   '/api/user/query', {
    //   queryStr: question
    // });

    var answer = '';

    try {
      const response = await post('/api/useOpenai1/query',
        // const response = await post('/api/user/query',
        {
          "queryStr": question
        });


      answer = response.data;
      // console.log(" 1 =======" + JSON.stringify(answer));
    } catch (error) {
      answer = '发生未知错误：' + JSON.stringify(error);
    }
    setIsButtonDisabled(false);

    return [...answers, answer];
  }



  // api 清空
  async function handleReset() {
    await post('/api/useOpenai1/clear',
      {});

    setQuestion('');
    setAnswers([]);
  };

  return (
    <div className="question-and-answer">
      <div className="answers">
        <h2>提问：</h2>
        {answers.map((answer, index) => (
          <p key={index}>{answer}</p>
        ))}
      </div>
      <form onSubmit={handleQuestionSubmit} className="question-form">
        <button type="button" onClick={handleReset} className="reset-button">重置</button>
        <textarea
          rows={4}
          value={question}
          onChange={handleQuestionChange}
          placeholder="请输入你的问题..."
          className="question-input"
        />
        <button type="submit" disabled={isButtonDisabled}>提交问题</button>
      </form>
    </div>
  );
};

export default QuestionAndAnswer;