import axios from 'axios';

// axiosのインストールを作成する
const axiosInstance = axios.create({
    timeout: 30000
});


// 汎用のHeader
axiosInstance.defaults.headers.post['Content-Type'] = 'application/json';

// axiosInstance.defaults.headers.get['Content-Type'] = 'application/json';
// axiosInstance.defaults.headers.get['Access-Control-Allow-Origin'] = '*';

/** GETのカブセル **/
export const get = async(url, data, config = {}) => {
    return new Promise((resolve, reject) => {
        axiosInstance({
                method: 'get',
                url,
                // headers: {
                //     "Content-Type": "application/json",
                //     "Access-Control-Allow-Origin": "*"
                // },
                data,
                ...config
            })
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

/** POSTのカブセル **/
export const post = async(url, data, config = {}) => {
    return new Promise((resolve, reject) => {
        axiosInstance({
                method: 'post',
                url,
                data,
                ...config
            })
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
};